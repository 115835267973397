import { createRoot } from "react-dom/client";
import { AuthContextProvider } from "./auth/AuthContext";
import Router from "./Router";


// import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>,
);
