import React, { useState, useRef } from 'react';
import axios from 'axios';
import { getCSRFToken } from './lib/django';

const apiBaseUrl = process.env.REACT_APP_API_URL;

const UploadForm = () => {
  const [files, setFiles] = useState([]);
  const [showFileList, setShowFileList] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false); // Controls file input visibility
  const [isValidated, setIsValidated] = useState(false);
  const inputRef = useRef(null);
  const csrfToken = getCSRFToken();
  const [totalProgress, setTotalProgress] = useState(0); // State for overall progress
  const [totalBytesUploaded, setTotalBytesUploaded] = useState(0);
  const [totalBytesToUpload, setTotalBytesToUpload] = useState(0);

  const getContentType = (fileName) => {
    if (fileName.toLowerCase().endsWith('.nii.gz')) {
      return 'application/gzip';
    } else if (fileName.toLowerCase().endsWith('.json')) {
      return 'application/json';
    } else if (fileName.toLowerCase().endsWith('.tsv')) {
      return 'text/tab-separated-values';
    }
    return 'application/octet-stream'; // Default MIME type
  };

  const allowedExtensions = ['.nii.gz', '.json', '.tsv'];

  const isAllowedExtension = (fileName) => {
    return allowedExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
  };

  const handleFileChange = (event) => {
    const allFiles = Array.from(event.target.files);
    const invalidFiles = [];
    const selectedFiles = allFiles
      .filter((file) => {
        const relativePath = file.webkitRelativePath || file.name;
        const pathComponents = relativePath.split('/');

        // Exclude files where any part of the path starts with a '.'
        if (pathComponents.some((component) => component.startsWith('.'))) {
          return false;
        }

        // Check if the file has an allowed extension
        if (isAllowedExtension(file.name)) {
          return true;
        } else {
          invalidFiles.push(relativePath);
          return false;
        }
      })
      .map((file) => ({
        id: Date.now() + Math.random(), // Unique identifier
        file,
        relativePath: file.webkitRelativePath || file.name,
        progress: 0, // Initialize progress to 0
      }));

    if (invalidFiles.length > 0) {
      alert(`The following files are not allowed and will be ignored:\n${invalidFiles.join('\n')}`);
    }

    if (!selectedFiles.length) {
      alert('No valid files selected. Please choose files with extensions: .nii.gz, .json, .tsv');
      return;
    }

    const bytesToUpload = selectedFiles.reduce((acc, fileObj) => acc + fileObj.file.size, 0);
    setTotalBytesToUpload(bytesToUpload);
    const filesWithProgress = selectedFiles.map((fileObj) => ({
      ...fileObj,
      uploadedBytes: 0,
      progress: 0,
    }));

    setFiles(filesWithProgress);
    setIsValidated(false); // Reset validation when files change
    setTotalProgress(0); // Reset overall progress
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!files.length) return;

    try {
      // Prepare data for requesting presigned URLs
      const fileInfos = files.map(({ file, relativePath }) => ({
        relativePath,
        contentType: getContentType(file.name),
      }));

      // Determine the base directory from the first file's relative path
      const baseDirectory = files[0].relativePath.split('/')[0]; // This should be '{study_name}'

      // Define the relative path for 'upload_complete.txt'
      const markerRelativePath = `${baseDirectory}/upload_complete.txt`;

      // Add marker file info
      const markerFileInfo = {
        relativePath: markerRelativePath, // Use the stored relative path
        contentType: 'text/plain',
      };
      fileInfos.push(markerFileInfo);

      // Request presigned URLs, including for 'upload_complete.txt'
      const response = await axios.post(
        `${apiBaseUrl}/generate_presigned_urls/`,
        { files: fileInfos },
        {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
          },
          withCredentials: true, // Ensure cookies are sent
        }
      );

      const presignedUrls = response.data.presignedUrls;

      // Retrieve the presigned URL for 'upload_complete.txt' using the correct relative path
      const markerUrlObj = presignedUrls.find(
        (obj) => obj.relativePath === markerRelativePath
      );
      // Remove the marker file from the list of files to upload initially
      const presignedUrlsWithoutMarker = presignedUrls.filter(
        (obj) => obj.relativePath !== markerRelativePath
      );

      // Upload each file using the presigned URL with progress tracking
      const uploadPromises = presignedUrlsWithoutMarker.map(async ({ relativePath, url, key }) => {
        const fileObj = files.find((f) => f.relativePath === relativePath);
        if (fileObj) {
          try {
            await axios.put(url, fileObj.file, {
              headers: {
                'Content-Type': getContentType(fileObj.file.name),
              },
              onUploadProgress: (progressEvent) => {
                const { loaded } = progressEvent;
                const totalFileSize = fileObj.file.size;

                // Update individual file progress and uploaded bytes, and compute totalUploadedBytes
                setFiles((prevFiles) => {
                  const updatedFiles = prevFiles.map((f) => {
                    if (f.id === fileObj.id) {
                      const progress = Math.round((loaded * 100) / totalFileSize);
                      return {
                        ...f,
                        progress,
                        uploadedBytes: loaded,
                      };
                    }
                    return f;
                  });

                  // Compute totalUploadedBytes
                  const totalUploadedBytes = updatedFiles.reduce(
                    (acc, f) => acc + (f.uploadedBytes || 0),
                    0
                  );

                  // Compute overall progress
                  const overallProgress = Math.round(
                    (totalUploadedBytes * 100) / totalBytesToUpload
                  );

                  setTotalProgress(overallProgress);

                  return updatedFiles;
                });
              },
            });

            // Notify the server about the successful upload
            await axios.post(
              `${apiBaseUrl}/log_file_upload/`,
              { key, relativePath },
              {
                headers: {
                  'X-CSRFToken': csrfToken,
                  'Content-Type': 'application/json',
                },
                withCredentials: true,
              }
            );

          } catch (uploadError) {
            console.error(`Error uploading ${relativePath}:`, uploadError);
            throw uploadError; // This will be caught by the outer try-catch
          }
        }
      });

      // Wait for all file uploads to complete
      await Promise.all(uploadPromises);

      // **Now upload 'upload_complete.txt'**
      try {
        if (!markerUrlObj) {
          throw new Error('Presigned URL for upload_complete.txt not found.');
        }
        const { url, key } = markerUrlObj;

        // **Generate 'upload_complete.txt' content using JavaScript**
        const markerFileContent = 'Upload complete';
        const markerFile = new Blob([markerFileContent], { type: 'text/plain' });

        // Upload 'upload_complete.txt' using the presigned URL
        await axios.put(url, markerFile, {
          headers: {
            'Content-Type': 'text/plain',
          },
        });

        // Optionally, notify the server about the successful upload of the marker file
        await axios.post(
          `${apiBaseUrl}/log_file_upload/`,
          { key, relativePath: markerRelativePath },
          {
            headers: {
              'X-CSRFToken': csrfToken,
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );

        // **All uploads completed successfully**
        alert('Files uploaded successfully.');
      } catch (markerError) {
        console.error('Error uploading upload_complete.txt:', markerError);
        alert('An error occurred while uploading upload_complete.txt.');
      }

      // Clear the files from state
      setFiles([]);
      setShowFileInput(false); // Hide file input after upload

      // Reset the file input's value
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      setTotalProgress(0);
    } catch (error) {
      console.error('Upload error:', error);
      alert('An error occurred during upload.');
    }
  };

  const handleClearSelection = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setFiles([]);
    setShowFileInput(false); // Hide file input when clearing selection
    setIsValidated(false); // Reset validation
  };

  const toggleFileList = () => {
    setShowFileList((prevShowFileList) => !prevShowFileList);
  };

  // Handler to remove a specific file
  const handleRemoveFile = (id) => {
    const updatedFiles = files.filter((file) => file.id !== id);
    setFiles(updatedFiles);

    // Optionally, reset validation if no files are left
    if (updatedFiles.length === 0) {
      setShowFileInput(false);
      setIsValidated(false);
    }
  };

  // Function to open BIDS Validator
  const handleValidateClick = () => {
    window.open('https://bids-standard.github.io/bids-validator/', '_blank');
    setShowFileInput(true); // Show file input after validation link is clicked
  };

  return (
    <form onSubmit={handleSubmit} className="file-uploader">
      <div className="validation-section">
        <button type="button" onClick={handleValidateClick}>
          Validate Data Structure
        </button>
        {/* "Choose Files" button is shown only after validation */}
        {showFileInput && (
          <>
            {files.length === 0 ? (
              // Initial state: Show file input
              <div className="form-group">
                <label htmlFor="file-input"></label>
                <input
                  id="file-input"
                  type="file"
                  ref={inputRef}
                  onChange={handleFileChange}
                  multiple
                  webkitdirectory=""
                  directory=""
                  mozdirectory=""
                  style={{ width: '95px', overflow: 'hidden' }}
                  accept=".nii.gz,.tsv,.json"
                />
              </div>
            ) : (
              // When files are selected: Show the file list with remove options and upload/clear buttons
              <>
                <div className="file-list">
                  <h3 onClick={toggleFileList} style={{ cursor: 'pointer' }}>
                    {showFileList ? 'Hide' : 'Show'} Selected Files
                  </h3>
                  {showFileList && (
                    <ul>
                      {files.map(({ id, relativePath, progress }) => (
                        <li key={id} className="file-item">
                          <div className="file-info">
                            {relativePath}
                            <button
                              type="button"
                              className="remove-button"
                              onClick={() => handleRemoveFile(id)}
                            >
                              Remove
                            </button>
                          </div>
                          {progress > 0 && (
                            <div className="progress-bar">
                              <div
                                className="progress"
                                style={{ width: `${progress}%` }}
                              >
                                {progress}%
                              </div>
                            </div>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="file-input"></label>
                  <input
                    id="file-input"
                    type="file"
                    ref={inputRef}
                    onChange={handleFileChange}
                    multiple
                    webkitdirectory=""
                    directory=""
                    mozdirectory=""
                    style={{ width: '95px', overflow: 'hidden' }}
                  />
                  <button type="submit">Upload Files</button>
                  <button type="button" onClick={handleClearSelection}>
                    Clear Selection
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {totalProgress > 0 && (
        <div className="overall-progress-bar" style={{ width: '100%', marginTop: '10px' }}>
          <div
            className="progress"
            style={{
              width: `${totalProgress}%`,
              height: '20px',
              backgroundColor: '#4caf50',
              textAlign: 'center',
              lineHeight: '20px',
              color: 'white',
            }}
          >
            {totalProgress}%
          </div>
        </div>
      )}
    </form>
  );
};

export default UploadForm;
