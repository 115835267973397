import React from 'react';
import { Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import App from './App';
import { AuthContextProvider } from './auth';

export default function Root() {
  return (
    <AuthContextProvider>
      <NavBar />
      <div className='container'>
        <Outlet />
      </div>
    </AuthContextProvider>
  );
}