import { useUser, useConfig } from './auth'
import { useLocation, Link } from 'react-router-dom'
import { useState } from "react";

function NavBarItem (props) {
  const location = useLocation()
  const isActive = (props.href && location.pathname.startsWith(props.href)) || (props.to && location.pathname.startsWith(props.to))
  const cls = isActive ? 'nav-link active' : 'nav-link'
  return (
    <li className='nav-item'>
      {props.href
        ? <a className={cls} href={props.href}>{props.icon} {props.name}</a>
        : <Link className={cls} to={props.to}>{props.icon} {props.name}</Link>}
    </li>
  )
}

export default function NavBar () {
  const user = useUser()
  const config = useConfig()
  const anonNav = (
    <>
      <NavBarItem to='/account/login' name='Login' />
      <NavBarItem to='/account/signup' name='Signup' />
      <NavBarItem to='/account/password/reset' name='Reset password' />
    </>
  )
  const authNav = (
    <>
      <NavBarItem to="/upload" name="Upload" />
      <NavBarItem to='/account/email' name='Change Email' />
      <NavBarItem to='/account/password/change' name='Change Password' />
      {/*config.data.socialaccount
        ? <NavBarItem to='/account/providers' name='Providers' />
        : null}*/}
      {/*config.data.mfa
        ? <NavBarItem to='/account/2fa' name='Two-Factor Authentication' />
        : null}*/}
      {config.data.usersessions
        ? <NavBarItem to='/account/sessions' name='Sessions' />
        : null}
      <NavBarItem to='/account/logout' name='Logout' />
    </>
  )

  const [imageSrc, setImageSrc] = useState("/images/radiata_logo_gray.png");

  const handleMouseOver = () => {
    setImageSrc("/images/radiata_logo_glow.png");
  };

  const handleMouseOut = () => {
    setImageSrc("/images/radiata_logo_gray.png");
  };

  return (
    <nav className='navbar'>
      <Link to='/' className='navbar-brand'>
        <div className="page-header">
          <img 
            src={imageSrc} 
            onMouseOver={handleMouseOver} 
            onMouseOut={handleMouseOut} 
            width="200" 
            style={{verticalAlign: 'middle'}}
          />
        </div>
      </Link>
      <ul className='navbar-nav'>
        {user ? authNav : anonNav}
      </ul>
    </nav>
  );
}
